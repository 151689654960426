@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Roobert";
  src: url(./assets/fonts/Roobert-Regular.ttf);
}
ol, ul, menu{
  list-style: outside !important;
}
body {
  margin: 0;
  font-family: "Roobert", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060606;
}

code {
  font-family: "Roobert", sans-serif;
}

.slick-slide > div {
  margin: 0 10px;
}
.wmde-markdown{
  line-height: 1 !important;
}
.wmde-markdown h1, .wmde-markdown h2, .wmde-markdown h3, .wmde-markdown h4, .wmde-markdown h5, .wmde-markdown h6 {
    margin-bottom: 8px !important;
}