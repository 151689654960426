.gigs {
  @apply flex flex-col mx-auto w-full;
  overflow-x: hidden;
  &_banner {
    @apply flex justify-between items-center mx-auto mt-32 md:mt-0;
    width: 90%;
    &_left {
      &_title {
        @apply text-6xl font-bold;
        line-height: 125%;
        background: linear-gradient(95deg, #f7b590 8.9%, #f58241 98.7%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &_subtitle {
        @apply font-bold text-2xl text-right;
        color: #7d7d7d;
      }
    }
    &_right {
      @apply mt-8;
      transform: translateX(10%);
      img {
        height: 200px;

        @media (min-width: 768px) and (max-width: 1239px) {
          height: 450px;
          transform: translateX(20%);
        }

        @media (min-width: 1240px) and (max-width: 1559px) {
          height: 500px;
          transform: translateX(15%);
        }
        @media (min-width: 1560px) {
          height: 600px;
        }
      }
    }
  }
  &_main {
    @apply flex flex-col mx-auto;
    width: 90%;
    &_title {
      @apply text-xl sm:text-3xl font-medium;
      background: linear-gradient(
        94deg,
        #fff 6.87%,
        rgba(255, 255, 255, 0.64) 103.8%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &_subtitle {
      @apply font-medium mt-2 mb-2 sm:mb-8 text-base sm:text-lg;
      color: #707070;
    }
    &_category {
      @apply relative gap-10 px-8 py-1 sm:py-3 items-center justify-center border-b overflow-x-auto;
      border-color: #494949;
      &::-webkit-scrollbar {
        display: none;
      }
      &_tabs {
        @apply flex space-x-16 lg:text-xl text-base font-semibold;
        color: #494949;
        &_text {
          @apply text-xl font-bold;
        }
      }
    }
    &_cardscontainer {
      @apply flex flex-col w-full;
      &_cards {
        @apply flex flex-col gap-4 mt-4 sm:mt-8 mb-8;
        &_card {
          @apply p-2 sm:p-4 rounded-xl flex flex-col md:flex-row cursor-pointer;
          border: 1px solid #252525;
          background: #111;
          &_left {
            @apply flex gap-4;
            width: 65%;
            &_image {
              @apply h-12 w-12 sm:h-32 sm:w-32;
              img {
                @apply w-full object-cover;
              }
            }
            &_details {
              @apply flex flex-col font-medium w-full;
              &_top {
                @apply flex flex-row gap-2 justify-between md:justify-normal;
                &_type {
                  @apply rounded-md p-2 text-sm sm:text-base;
                  background-color: #252525;
                  color: #7df5d1;
                }
                &_amount {
                  @apply rounded-md p-2 text-sm sm:text-base;
                  background-color: #252525;
                  color: #f7c487;
                }
              }
              &_name {
                @apply text-white font-semibold mt-2 text-sm sm:text-base;
              }
              &_by {
                @apply text-xs mt-0.5;
                color: #adadad;
              }
              &_desc {
                @apply hidden sm:block text-sm mt-3;
                color: #878787;
              }
            }
          }
          &_right {
            @apply flex items-end flex-col relative;
            width: 35%;
            &_submission {
              @apply rounded-md w-fit h-fit p-2 text-xs sm:text-xs;
              background: rgba(142, 212, 141, 0.12);
              color: #8ed48d;
            }
            &_tags {
              @apply hidden md:flex gap-2 mt-2 sm:mt-4 mb-2;
              &_tag {
                @apply rounded-3xl py-1 px-2 text-xs md:text-sm xl:text-base mx-0.5;
                color: #9d9d9d;

                border: 1px solid #323232;
                background: #252525;
              }
            }
            &_date {
              @apply text-xs sm:text-base md:absolute md:bottom-0;
              color: #787878;
            }
          }
          @media (max-width: 1024px) {
            &_right,
            &_left {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1240px) and (max-width: 1559px) {
  .gigs {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .gigs {
    width: 1350px;
  }
}
