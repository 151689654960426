.chainheader {
  @apply mt-4 fixed mx-auto left-0 right-0 flex justify-center items-center p-1 z-50;
  width: 90vw;
  border-radius: 14px;
  border: 1px solid #262626;
  background: #0b0b0b;
  &_inner {
    @apply flex w-full rounded-xl p-1;
    border: 1px solid #1b1b1b;
    background: #060606;
    &_inner {
      @apply rounded-xl py-2 px-4 flex md:justify-between items-center font-medium flex-wrap md:flex-nowrap gap-4 md:gap-0 justify-center;
      width: 100%;
      border: 1px solid #141414;
      background: #171717;
      background: #0e0f10;
      &_left {
        @apply text-white;
        img {
          @apply w-20 lg:w-32 xl:w-40;
        }
      }
      &_center {
        @apply flex gap-4 text-sm sm:text-lg p-2 rounded-xl;
        color: rgba(218, 218, 218, 0.5);
        border: 1.5px solid var(--border, #252525);

        &_tab {
          @apply py-2  px-2 lg:px-4 xl:px-6 rounded-lg;

          span {
            @apply px-2 py-1 ml-1 rounded-3xl text-lg;
            background: #272727;
          }
        }
      }
      &_right {
        @apply gap-2 md:gap-4 xl:gap-6 items-center text-sm xl:text-base;
        &_profileimagecontainer {
          @apply h-12 w-12 lg:h-14 lg:w-14 xl:h-16 xl:w-16 rounded-full flex justify-center cursor-pointer items-center font-black text-2xl;
          background-image: url("../../../assets/images/profileimagebg.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          color: #ae9afc;
        }
        &_text {
          @apply font-medium;
          color: #5b5b5b;
        }
        &_button {
          @apply rounded-md px-2 py-2 lg:px-2.5 lg:py-2.5 xl:px-3 xl:py-3 text-white cursor-pointer;
          background: #414141;
        }
        &_logoutbutton {
          @apply rounded-md flex items-center justify-center px-1 py-1 lg:px-1.5 lg:py-1.5 xl:px-2 xl:py-2 text-white cursor-pointer;
          background: #282727;
          img{
            @apply w-8;
          }
        }
      }
    }
  }
}
@media (min-width: 1240px) and (max-width: 1559px) {
  .chainheader {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .chainheader {
    width: 1350px;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  position: absolute;
  margin-top: 10px ;
  border-radius: 10px;
  border: 1px solid #323232;
  background: #161616;
  min-width: 195px;
  padding: 10px;
  z-index: 1;
}

/* Dropdown links */
.dropdown-link {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  span{
    @apply rounded-3xl px-2 py-1 text-xs;
    background: #272727;
    color: #8B8B8B;
  }
  // transition: background-color 0.3s;
}

/* Dropdown links on hover */
.dropdown-link:hover {
  border-radius: 6px;
  border: 1px solid #353535;
  background: #262626;
}
