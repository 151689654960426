.events {
  @apply flex flex-col mx-auto;
  overflow-x: hidden;
  &_banner {
    @apply flex justify-between items-center mx-auto mt-32 md:mt-0;
    width: 90%;
    &_left {
      &_title {
        @apply text-6xl font-bold;
        line-height: 125%;
        // letter-spacing: 4px;
        background: linear-gradient(97deg, #90f7c5 9.46%, #32f3e8 108.25%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &_subtitle {
        @apply font-bold text-2xl text-right;
        color: #7d7d7d;
        // text-transform: capitalize;
      }
    }
    &_right {
      @apply mt-8;
      transform: translateX(10%);
      img {
        height: 200px;

        @media (min-width: 768px) and (max-width: 1239px) {
          height: 450px;
          transform: translateX(20%);
        }

        @media (min-width: 1240px) and (max-width: 1559px) {
          height: 500px;
          transform: translateX(15%);
        }
        @media (min-width: 1560px) {
          height: 600px;
        }
      }
    }
  }
  &_main {
    @apply flex flex-col mx-auto;
    width: 90%;
    &_title {
      @apply text-xl sm:text-3xl font-medium;
      background: linear-gradient(
        94deg,
        #fff 6.87%,
        rgba(255, 255, 255, 0.64) 103.8%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &_subtitle {
      @apply font-medium mt-2 sm:mb-8 text-base sm:text-lg;
      color: #707070;
      &_span {
        color: #f1f1f1;
        text-transform: capitalize;
      }
    }
    &_cardscontainer{
      @apply flex flex-col mt-12 mb-4;
      &_date{
        @apply text-white mb-2 ml-1;
      }
      &_card{
          @apply p-2 sm:p-4 rounded-xl flex cursor-pointer mb-3;
          border: 1px solid #252525;
          background: #111;        
          &_left{
            @apply flex;
          width: 75%;
          img{
            height: 60px;
            width: 60px;
          }
          &_details{
            @apply flex flex-col ml-4 text-white;
            &_title{
              @apply font-bold;
            }
            &_subtitle{
              @apply text-xs mt-1;
            }
          }
        }
        &_right{
          @apply flex justify-center items-center font-black text-white text-xl mb-2;
          width: 25%;
        }
      }
    }

  }
}
@media (min-width: 1240px) and (max-width: 1559px) {
  .events {
    width: 1200px;
  }
}
@media (min-width: 1560px) {
  .events {
    width: 1350px;
  }
}
