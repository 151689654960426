.createeventpage {
  @apply flex w-full h-screen md:overflow-hidden;
  background-image: url(../../assets/images/creategigbg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &_main {
    @apply flex justify-center flex-row w-full mt-48 sm:mt-40 gap-24;
    &_steppercontainer {
      @apply hidden xl:block mt-24;
    }
    &_formcontainer {
      @apply flex flex-col items-center;
      &_title {
        @apply text-2xl text-white;
      }
      &_subtitle {
        @apply mt-2 text-center w-9/12 sm:w-full;
        color: #898989;
      }
      &_form {
        @apply flex flex-col mt-8 gap-2 sm:gap-4  w-[350px] sm:w-[600px]   lg:w-[750px] rounded-xl;
        border: 1px solid #303030;
        &_header {
          @apply rounded-t-xl w-full px-4 py-4 sm:py-6 flex text-xl font-semibold;
          background: #171717;
          color: #d9d9d9;
        }
        &_content {
          @apply  flex-col w-full p-3 sm:p-6 h-[350px] sm:h-[400px] flex;
          overflow: scroll;
        }
        &_footer {
          @apply flex flex-col space-y-2 lg:space-y-0 lg:flex-row w-full justify-between items-center p-3;
          background: var(--material-90, #1d1d1d);
          &_container {
            @apply flex justify-between w-full space-x-12 items-center;
          }
          &_disclaimertext {
            @apply font-semibold lg:text-base text-sm;
            color: #646464;
            span {
              color: #f55571;
            }
          }
          &_buttoncontainer {
            @apply flex gap-3 items-center lg:text-base text-sm font-semibold;
            &_previous {
              color: #ffffff;
              @apply cursor-pointer;
            }
            &_next {
              @apply cursor-pointer  lg:px-4 px-2 py-3 text-center w-[140px] sm:w-[180px] rounded-lg;
              color: #101012;
              background: #f2f2f2;
              box-shadow: 0px 2px 1px 0px #fff inset,
                0px -2px 1px 0px rgba(10, 10, 10, 0.29) inset;
            }
          }
        }
      }
    }
  }
}
.basicscomponent {
  @apply flex flex-col;
  &_questioncontainer {
    @apply rounded-md p-3 mb-4 flex flex-col gap-3;
    border: 1px solid #161616;
    background: #101010;
    &_top {
      @apply flex justify-between items-center;
      &_title {
        @apply font-medium;
        color: #757575;
      }
      &_right {
        @apply flex gap-3 items-center;
        &_value {
          @apply p-1 rounded-lg outline-none;
          border: 1px solid rgba(48, 48, 48, 0.3);
          background: #141414;
          color: rgba(255, 255, 255, 0.4);
        }
        img {
          @apply w-5 h-5 cursor-pointer;
        }
      }
    }
    &_inputtitle {
      @apply rounded-md w-full outline-none px-3 py-3 font-medium;
      border: 1px solid rgba(122, 122, 122, 0.3);
      background: #0d0d0d;
      color: #fff;
    }
    &_inputsubtitle {
      @apply rounded-md w-full outline-none px-3 py-3;
      border: 1px solid rgba(122, 122, 122, 0.3);
      background: #0d0d0d;
      color: #c0c0c0;
    }
  }
  &_addquestionbutton {
    @apply flex justify-center items-center w-full p-3 rounded-md mt-4 cursor-pointer;
    color: #c8c8c8;
    background: #3c3c3c;
  }
  &_inputcontainer {
    @apply flex flex-col text-white mb-4;
    &_title {
      @apply font-medium;
      color: #6a6a6a;
    }
    &_questiontitle {
      @apply text-lg font-semibold;
      color: #e9e9e9;
    }
    &_questiondescription {
      @apply text-base font-medium mb-2;
      color: #6a6a6a;
    }

    &_value {
      @apply flex gap-2 mt-2 rounded-md p-2.5 sm:p-4 flex-wrap outline-none;
      border: 1px solid #2b2b2b;
      background: #181818;
      color: #DADADA;
      &_tags {
        @apply flex gap-1 flex-wrap;
        &_tag {
          @apply rounded-3xl px-2;
          border: 1px solid #2f2f2f;
          opacity: 0.8;
          background: #212121;

          button {
            @apply ml-1 font-black;
            color: #717172;
          }
        }
      }
      input {
        @apply bg-inherit outline-none w-72;
      }
    }
  }
  &_rewardcurrency {
    @apply p-2 py-3 rounded-lg outline-none;
    border: 1px solid rgba(48, 48, 48, 0.3);
    background: #141414;
    color: #EFEFEF;
  }
  &_rewardcontainer {
    @apply flex flex-col w-full text-white p-3 rounded-md mt-4;
    border: 1px solid #2c2c2c;
    background: #131313;

    &_rewardserial {
      @apply font-medium;
      color: #6a6a6a;
    }
    &_input {
      @apply rounded-md p-3 mt-3 outline-none;
      border: 1px solid #2b2b2b;
      background: #181818;
    }
  }
}
// <span class="MuiStepConnector-line MuiStepConnector-lineVertical css-8t49rw-MuiStepConnector-line"></span>
.wmde-markdown,
.w-md-editor-toolbar {
  background-color: inherit !important;
}
